import { computed, inject, Injectable, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AuthService } from '@logic-suite/shared/auth';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {
  AclGroup,
  AclPermissions,
  AclResource,
  AclResourceType,
  AclRole,
  AclRoleType,
  AclScope,
} from '@logic-suite/shared/acl/acl.interface';
import { catchError, EMPTY, Subject } from 'rxjs';
import { Logger } from '@logic-suite/shared/logger/logger.service';

interface AclServiceState {
  loaded: boolean;
  error: string | null;
  resources: AclResource[];
  groups: AclGroup[];
  roles: AclRole[];
}

@Injectable({ providedIn: 'root' })
export class AclService {
  http = inject(HttpClient);
  logger = inject(Logger);
  authService = inject(AuthService);
  private readonly API_BASE_URL = '/api/shared/v1/Permissions';

  // Sources
  error$ = new Subject<string | null>();
  loadedPermissions$ = this.authService.isLoggedIn$.pipe(
    distinctUntilChanged(),
    switchMap(() => this.fetchPermissions()),
  );

  // State
  private readonly state = signal<AclServiceState>({
    loaded: false,
    error: null,
    resources: [],
    groups: [],
    roles: [],
  });

  // Selectors
  loaded = computed(() => this.state().loaded);
  error = computed(() => this.state().error);
  resources = computed(() => this.state().resources);
  groups = computed(() => this.state().groups);
  roles = computed(() => this.state().roles);
  // Computed selectors
  groupResources = computed(() => this.groups().flatMap((group) => group.resources));
  allResources = computed(() => [...this.resources(), ...this.groupResources()]);
  scopesForResource = computed(
    () => (resourceId: string, resourceType: AclResourceType) => this.getScopesForResource(resourceId, resourceType),
  );
  hasScopes = computed(() => (resourceId: string, resourceType: AclResourceType, scopes: AclScope[]) => {
    if (scopes.length === 0) {
      return false;
    }

    const scopesForResource = this.scopesForResource()(resourceId, resourceType);
    return scopes.every((scope) => scopesForResource.includes(scope));
  });

  constructor() {
    // Reducers
    this.loadedPermissions$.pipe(takeUntilDestroyed()).subscribe((permissions) => {
      this.state.update((state) => ({
        ...state,
        loaded: true,
        error: null,
        resources: permissions.resources,
        groups: permissions.groups,
        roles: permissions.roles,
      }));
    });
  }

  // Private methods
  private fetchPermissions() {
    // Uncomment for local testing.
    // return of(AclMocks.test16617);
    return this.http.get<AclPermissions>(this.apiUrl()).pipe(
      catchError((err: HttpErrorResponse) => {
        this.logger.error(`Could not fetch permissions.`, 'aclService', err);
        this.error$.next(err.statusText);
        return EMPTY;
      }),
    );
  }

  private getRoleFromRoleType(roleType: AclRoleType): AclRole | null {
    const role = this.roles().find((role) => role.id === roleType);

    if (!role) {
      return null;
    }

    return role;
  }

  private getScopesForRoleType(roleType: AclRoleType): AclScope[] {
    const role = this.getRoleFromRoleType(roleType);

    if (role === null) {
      return [];
    }

    return role.scopes;
  }

  private getScopesForResource(resourceId: string, resourceType: AclResourceType): AclScope[] {
    return this.allResources()
      .filter((resource: AclResource) => resource.type === resourceType && resource.id === resourceId)
      .flatMap((resource: AclResource) => resource.roleType)
      .map((roleType: AclRoleType) => this.getScopesForRoleType(roleType))
      .flat();
  }

  private apiUrl(path: string = ''): string {
    return `${this.API_BASE_URL}/${path}`;
  }
}
