import { Directive, effect, inject, input, TemplateRef, ViewContainerRef } from '@angular/core';
import { CustomerService } from '../../../../../apps/suite/src/app/shared/access';
import { AclService } from '@logic-suite/shared/acl/acl.service';

/**
 * Checks permissions for the currently selected customer.
 *
 * If you need to check scopes for a specific customer or resource,
 * use [libHasScopes] instead.
 */
@Directive({
  selector: '[libCustomerHasScopes]',
  standalone: true,
})
export class CustomerHasScopesDirective {
  private templateRef = inject(TemplateRef<unknown>);
  private viewContainer = inject(ViewContainerRef);
  private aclService = inject(AclService);
  private customerService = inject(CustomerService);

  // libCustomerPermissions = input.required<string[]>();
  libCustomerHasScopes = input.required<string[]>();
  libCustomerHasScopesElse = input<TemplateRef<unknown> | null>(null);

  constructor() {
    effect(() => this.updateView());
  }

  private updateView() {
    const scopes = this.libCustomerHasScopes();
    const elseTemplate = this.libCustomerHasScopesElse();

    this.viewContainer.clear();

    const customerId = this.customerService.getSelectedCustomerId();
    if (customerId === null) {
      return;
    }

    if (this.aclService.hasScopes()(customerId.toString(), 'customer', scopes)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else if (elseTemplate) {
      this.viewContainer.createEmbeddedView(elseTemplate);
    }
  }
}
