import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { getEnv } from '@logic-suite/shared/utils';

if (environment.production) {
  enableProdMode();
}

Sentry.init({
  dsn: getEnv('sentryDSN'),
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Performance monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /noova.no\/api/],
  // Session replay
  // Sample rate should be 10% for prod, but can be 100% for dev.
  // Uncomment to enable replays.
  // replaysSessionSampleRate: getEnv('env') === 'prod' ? 0.1 : 1.0,
  // replaysOnErrorSampleRate: 1.0,
  // Set environment.
  environment: getEnv('env'),
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
